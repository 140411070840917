<template>
  <CBox my="20px">
      <CGrid templateColumns="7fr 3fr" :gap="6" autoRows="min-content">
        <CBox w="100%" h="min-content" bg="white" border="1px" borderColor="borderBox">
            <CBox v-if="!currentUser.emp_id">
              <CBox h="20px" />
              <Loading />
            </CBox>
            <CBox v-if="currentUser.emp_id" mx="32px" my="20px">
              <CText fontWeight="bold" color="fontMain" my="10px">Pilih Desain Web CV</CText>
              <CBox maxH="calc(100vh - 310px)" overflowY="scroll">
                <ChooseWebCV :currentUser="currentUser" type="pending" @chooseDesign="chooseDesign" />
              </CBox>
            </CBox>
            <CDivider borderColor="borderBox" border="1.5px" v-if="currentUser.emp_id" />
            <CBox align="right" m="20px" v-if="currentUser.emp_id">
              <ButtonBlue label="Lanjutkan" @click.native="onSubmit()" :disabled="disabledButton" />
            </CBox>
        </CBox>
        <CBox>
          <CBox mx="10px" w="100%" bg="white" border="1px" borderColor="borderBox" style="position: relative;">
            <CBox class="coba" borderLeft="1px" borderBottom="1px" borderColor="borderBox" style="position: absolute; top: 20px;" h="10px" />
            <CBox my="20px" mx="15px">
              <CText fontWeight="bold" color="fontInformation">Pilih Desain Web CV</CText>
              <CText color="fontInformation" fontSize="14px">
                Pilih Desain WEB CV yang kamu inginkan. 
              </CText>
              <CImage mt="20px" :src="require('@/assets/img/choose-web-cv-illustration.png')" />
            </CBox>
          </CBox>
        </CBox>
      </CGrid>
      <!-- <WizardFooter @onSubmit="onSubmit()" :disabledButton="disabledButton" /> -->
    <!-- </CBox> -->
  </CBox>
</template>

<script>
import { GET_USER, UPDATE_USER_EMPLOYEE } from "@/store/auth.module"
import ButtonBlue from "@/components/button/ButtonBlue.vue"
import Swal from "sweetalert2";
import Loading from "@/components/Loading.vue";
import ChooseWebCV from "@/components/config-profile/CompChooseWebCV.vue";
// import WizardFooter from "@/components/footer/WizardFooter.vue";

export default {
  metaInfo: {
    title: "Pilih Desain",
    titleTemplate: "%s - Gethired.id"
  },
  components: {
    ButtonBlue,
    Loading,
    ChooseWebCV,
    // WizardFooter
  },
  data() {
    return {
      user: {},
      disabledButton: false,
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    }
  },
  methods: {
    async getData() {
      // this.disabledButton = true;
      // try {
        this.$store.dispatch(GET_USER)
      //   this.disabledButton = false;
      // } catch(err) {
      //   this.disabledButton = false;
      // }
    },
    chooseDesign(slug) {
      this.currentUser.web_cv_design_converted = slug;
    },
    onSubmit() {
      this.disabledButton = true;
      this.$store
        .dispatch(UPDATE_USER_EMPLOYEE, {
          id: this.currentUser.emp_id,
          data: {
            web_cv_design: this.currentUser.web_cv_design_converted
          }
        })
        .then(() => {
          this.disabledButton = false;
          this.$router.push({ name: "Dashboard Desain CV" })
        })
        .catch((err) => {
          this.disabledButton = false;
          let errData = err.message;
          if (err.response && err.response.data && err.response.data.message)
            errData = err.response.data.message;
          Swal.fire({
            title: "Oops . .",
            text: errData,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
        });
    },
  }
}
</script>

<style scoped>
.coba {
  content: "";
  left: calc(-0.5rem);
  transform: rotate(45deg);
  background-color: rgb(255, 255, 255);
  display: block;
  width: 1rem;
  height: 1rem;
}
</style>
